import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.button<{ fgColor: string, bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 47px;
  border-radius: 4px;
  border: 2px solid ${props => props.fgColor === '#FFFFFF' ? props.bgColor : props.fgColor};
  color: ${props => props.fgColor};
  background-color: ${props => props.bgColor};
  margin-right: 10px;
  @font-face {
    font-family: var(--Lato-font);
    url('../../../assets/fonts/Lato-Regular.ttf') format('truetype');
  }
  font-weight: 700;
  font-family: var(--Lato-font);
`;

