import { ChangeEvent, useState } from 'react';
import GoBackButton from "../../../components/buttons/GoBack";
import AccentButton from "../../../components/buttons/AccentButton";
import * as Styled from "./styled";
import { Row } from '../../../components/Row/styled';
import CreateCell from '../../../components/forms/createCell';
import StyleCellsView from '../../../components/buttons/StyleView';
import AddStation from '../../../components/buttons/AddStation';
import FieldStation from '../../../components/FieldStation';
import { useNavigate, useLocation } from 'react-router-dom';

import img1 from './imgs/Rectangle 2428.png';
import img2 from './imgs/Rectangle 2429.png';
import img3 from './imgs/Rectangle 2420.png';
import NewCellDto from '../../../dto/new-cell.dto';
import CellsService from '../../../services/cells.service';
import { parseError } from '../../../config/error-handler';
import NewWorkstationDto from '../../../dto/new-workstation.dto';
import { toast } from 'react-toastify';

const NewCellsPage = () => {

  const cellService = new CellsService();
  
  const [producao, setProducao] = useState("");
  const [meta, setMeta] = useState("");
  const [layout, setLayout] = useState<number>(1);
  const [selectedImage, setSelectedImage] = useState<string>(img1);

  const [stationCount, setStationCount] = useState<number>(0);
  const [isFinalIndex, setIsFinalIndex] = useState<number>();

  const [isFullCardVisible, setIsFullCardVisible] = useState(false);
  const [selectedStationIndex, setSelectedStationIndex] = useState<number | null>(null);
  const [workstations, setWorkstations] = useState<NewWorkstationDto[]>([]);

  //const [setWorkstationFinal, workstationFinal] = useState<>

  const incrementStationCount = () => {
    if (!producao) {
      alert("Por favor, preencha o campo de produção antes de adicionar uma estação.");
      return;
    }
    
    const newStation : NewWorkstationDto = {
      goal: 1,
      isFinal: false,
      orderOnCell: 0,
    };

    setWorkstations(prevWorkstations => {
      return [...prevWorkstations, newStation];
    });

    setStationCount(count => count + 1);
  };

  function handleDelete(index: number){
    if (selectedStationIndex !== null) {
      setSelectedStationIndex(null);
    }
    
    setWorkstations(workstations.filter((_, i) => i !== index));

    setStationCount(count => count - 1);

  };

  const handleStationSelection = (index: number) => {
    setSelectedStationIndex(index === selectedStationIndex ? null : index);
    setIsFullCardVisible(true);
  };

  const handleProductionChange = (value: string) => {
    if (!/\d/.test(value)) {
      setProducao(value.toUpperCase());
    }
  };

  const handleImageClick = (image: string, number: number) => {
    setSelectedImage(image);
    setLayout(number);
  };

  const handleFinalWorkstation = (event : ChangeEvent<HTMLSelectElement>) => {
    const updatedWorkstations = workstations
    const index = parseInt(event.target.value)
    
    if(isFinalIndex == null){
      updatedWorkstations[index].isFinal = true;
      setIsFinalIndex(index)
      setWorkstations(updatedWorkstations)
      console.log(updatedWorkstations)
      return
    }

    updatedWorkstations[isFinalIndex].isFinal = false
    updatedWorkstations[index].isFinal = true
    setWorkstations(updatedWorkstations)
    setIsFinalIndex(index)
    console.log(index)

  };

  const SaveCell = () => {
    if(workstations.findIndex((ws) => ws.isFinal) === -1){
      toast.error("Selecione um posto final !!")
      return
    }

    workstations.map((ws, key) => {
      workstations[key] = {
        goal: ws.goal,
        isFinal: ws.isFinal,
        orderOnCell: key+1
      }
    })
      
      const cell: NewCellDto = {
        name: producao,
        layout: layout,
        workstations: workstations,
      };
    
      cellService
        .createCell(cell)
        .then(() => navigate(-1))
        .catch(parseError); 
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <GoBackButton
        text="Criar Nova Célula"
        accentButton={
          <AccentButton
            onClick={SaveCell}
            title="Salvar"
            style={{ width: '140px' }}
          />
        }
      />
      <Row>
        <Styled.LeftColumn>
          <Styled.Card>
            <Row>
              <CreateCell labelText="PRODUÇÃO" onChange={handleProductionChange} value={producao} />
              {/*<CreateCell labelText="META DA CÉLULA" onChange={handleMetaCellChange} value={meta} />*/}
              <Styled.SelectField>
                <Styled.SelectLabel>EMPACOTAMENTO</Styled.SelectLabel>
                <Styled.SelectProp 
                  onChange={(event) => handleFinalWorkstation(event)} 
                  name="FinalWorkstation" 
                  id="FinalWorkstation">
                  <option value="" hidden>Selecione um posto</option>
                  {workstations.map((ws : NewWorkstationDto, key) => {
                    return (<option key={key} value={key}>{producao + (key + 1)}</option>)
                  })}

                </Styled.SelectProp>
              </Styled.SelectField>
            </Row>
            <Styled.h1>LAYOUT DA CÉLULA</Styled.h1>
            <Row>
              <StyleCellsView
                labelText="Opção 1"
                image={img1}
                onClick={() => handleImageClick(img1, 1)}
                isSelected={selectedImage === img1}
              />
              <StyleCellsView
                labelText="Opção 2"
                image={img2}
                onClick={() => handleImageClick(img2, 2)}
                isSelected={selectedImage === img2}
              />
              <StyleCellsView
                labelText="Opção 3"
                image={img3}
                onClick={() => handleImageClick(img3, 3)}
                isSelected={selectedImage === img3}
              />
            </Row>
          </Styled.Card>
        </Styled.LeftColumn>
        <Styled.RightColumn >
          <Styled.Card >
            <Row style={{ justifyContent: 'space-between' }}>
              <Styled.h1>POSTO DE TRABALHO</Styled.h1>
              <AddStation onClick={incrementStationCount} isProductionFilled={!!producao} />
            </Row>
            {stationCount > 0 && (
              workstations.map((station, index) => (
                <FieldStation
                  key={index}
                  isFinal={station.isFinal}
                  stationIndex={index + 1}
                  isSelected={selectedStationIndex === index + 1}
                  onSelect={() => handleStationSelection(index + 1)}
                  productionText={producao}
                  status={false}
                  handleDelete={() => handleDelete(index)}
                  stopReason=''
                />
              ))
            )}
          </Styled.Card>
        </Styled.RightColumn>
      </Row>
    </>
  );
};

export default NewCellsPage;