import React, { useEffect, useRef, useState } from 'react';
import Chart, { ChartData, ChartTypeRegistry, Plugin } from 'chart.js/auto'; // Importar tipagens ChartData e ChartTypeRegistry
import * as Styled from "./styled";
import ProductionDto from '../../../dto/production.dto';
import ProductionCell from '../../ProductionCell';

interface GoalChartProps{
  infos : ProductionDto[] 
}

interface AfterDrawPluginOptions {
  afterDraw: (chart: Chart, args: any) => void;
}

const GoalChart = ({infos} : GoalChartProps) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  let produzindo : number = 0;

  const maxValue = () => {
    const total = infos[0]?.meta + produzindo
    return total 
  }

  const teste = () => {
    let produzindoTeste = 0

    infos.map(value => {
      produzindoTeste += value.amount
      return produzindoTeste
    })
    
    const total = infos[0]?.meta + produzindoTeste
    return total - infos[0]?.meta
  }

  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    if (!ctx) return;

    
    const data: ChartData<keyof ChartTypeRegistry, number[], string> = {
      labels: infos.map( value => value.name ),
      datasets: [
        {
          label: '100% da Meta',
          data: infos.map(value => infos[0].meta),
          borderColor: '#108905',
          type: 'line',
          fill: false,
          borderDash: [4, 4], // Padrão tracejado com 8px de traço e 8px de espaço
          borderWidth: 3,
          yAxisID: 'y3',
        },
        {
          label: 'Meta',
          data: infos.map(value => {
            produzindo += value.amount
            return produzindo
          }),
          borderColor: '#B43131',
          type: 'line',
          fill: false,
          borderWidth: 3,
          yAxisID: 'y',
        },
        {
          label: 'Meta - móvel',
          data: infos.map(value => {
            return value.metaMovel
          }),
          borderColor: '#3ac22e',
          type: 'line',
          fill: false,
          borderWidth: 3,
          yAxisID: 'y4',
        },
        {
          label: 'Produzido',
          data: infos.map((value) =>  value.amount ),
          backgroundColor: '#454D5F',
          type: 'bar',
          barPercentage: 0.5,
          barThickness: 30,
          yAxisID: 'y1',
        },
      ],
    };

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          x: {
            type: 'category',
            position: 'bottom',
            ticks: {
              color: '#333',
              font: {
                family: 'Lato',
                size: 14,
                weight: 600,
              },
            },
          },
          // Grafico Barras
          y1: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            stacked: true,
            ticks: {
              color: '#80764B',
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },
          // Grafico Linha Azul
          y: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            ticks: {
              color: '#80764B',
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },
          // Grafico Linha Meta - Movel
          y4: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            ticks: {
              color: '#80764B',
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },
          // Grafico Linha Y=100
          y3: {
            display: false,
            beginAtZero: true,
            max: maxValue(),
            min: 0,
            ticks: {
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },
        },
        plugins:{
          legend: {
            display: true, 
          }
        },
        elements: {
          bar: {
            borderRadius: 2,
          },
          line: {
            fill: false,
            tension: 0.1,
          },
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  });

  return (
    <Styled.ChartContainer>
      <Styled.ContainerRow distanciaP={teste()}>
        <canvas ref={chartRef}></canvas>
      </Styled.ContainerRow>
    </Styled.ChartContainer>
  );
};

export default GoalChart;
