import RoutePath from "../models/route-path.model";
import Login from "../pages/login";


// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const publicRoutes: RoutePath[] = [
  { path: '/login' , element: Login },
];

export default publicRoutes;
