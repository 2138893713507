import React, { useEffect, useState } from 'react';
import * as Styled from './styled';
import Navbar from '../../../components/Navbar';
import BlockComponent from '../../../models/block-component.model';
import SideMenu from '../../../components/SideMenu';
import AuthService from '../../../services/auth.service';
import UserDto from '../../../dto/user.dto';
import { parseError } from '../../../config/error-handler';
import { Outlet } from 'react-router-dom';

const AuthenticatedLayout = () => {
  const authService = new AuthService()

  const [authenticatedUser, setAuthenticatedUser] = useState<UserDto>({
    id: 0,
    name: "",
    email: "",
    phone: "",
    workstation: "",
    shift: "",
    password: "",
    cell: 0,
    picture: "",
    role: 0
  })

  useEffect(() => {
    authService.getAuthenticatedUser()
     .then(user => {setAuthenticatedUser(user)})
     .catch(parseError)

  }, [])

  const [menuOpen, setMenuOpen] = useState(false);
  
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }
  
  return (
    <Styled.Container>
      <SideMenu hidden={!menuOpen} toggleMenu={toggleMenu} />
      <Styled.Header>
        <Navbar onMenuClick={toggleMenu} user={authenticatedUser}/>
      </Styled.Header>
      <Styled.Body>
        <Outlet/>
      </Styled.Body>
    </Styled.Container>
  );
}

export default AuthenticatedLayout;
