import React from 'react';
import ProgressBar from '../ProgressBar';
import * as Styled from "./styled";
import ButtonStations from "../../models/button-stations.model";

interface SummaryProps {
  selectedStationIndex: number | null;
  meta?: string;
  productionTotal?: string;
  productionNC?: string;
  workedHours?: string;
  unavailability?: string;
}

const Summary: React.FC<SummaryProps> = ({ selectedStationIndex, meta = "0", productionTotal = '0', productionNC = '0', workedHours = '0', unavailability = '0' }) => {
  const activeButton = selectedStationIndex; 
  
  const productionTotalPorcentage = parseFloat(productionTotal)
  const metaPercentage = parseFloat(meta)
  const percentage = selectedStationIndex !== null ? (productionTotalPorcentage/metaPercentage) * 100 : (productionTotalPorcentage/metaPercentage) * 100;

  return (
    <Styled.Summary>
      <table>
        <tbody>
          <tr>
            <th>Produção total</th>
            <td>{productionTotal} unidades</td>
          </tr>
          <tr>
            <th>Produção N/C</th>
            <td>{productionNC} unidades</td>
          </tr>
          <tr>
            <th>Horas trabalhadas</th>
            <td>{workedHours}</td>
          </tr>
          <tr>
            <th>Indisponibilidade</th>
            <td>{unavailability}</td>
          </tr>
        </tbody>
      </table>
      <Styled.ProgressBarContainer>
        <p>{activeButton !== null ? 'Desempenho do posto' : 'Desempenho da célula'}</p>
        <ProgressBar percent={percentage} />
      </Styled.ProgressBarContainer>
    </Styled.Summary>
  );
};

export default Summary;
