// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
export const buttonStyle = {
  fontFamily: "var(--Lato-font)",
  backgroundColor: '#80764B',
  color: 'white',
  padding: '12px 0',
  gap: '8px'
};
