import styled from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const Text = styled.h1`
  font-family: var(--Jost-font);
  font-weight: 500;
  font-size: 32px;
`;

export const ArrowBackIosNew = styled(ArrowBackIosNewIcon)`
  font-weight: 500;
  margin-right: 10px;
  font-size: large;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
