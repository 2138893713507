import * as Styled from "./styled";
import CircularChart from "../charts/CircularChart";
import ProductionCellDto from "../../dto/production-cell.dto";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const ProductionCell = ({ title, goal = 0, production = 0, nc = 0 }: ProductionCellDto) => (
  <Styled.Container>
    <Styled.RadialChart>
      <CircularChart 
        series={[goal === 0 ? 0 : parseFloat((production/goal*100).toFixed(1))]}
        size='regular'
      />
    </Styled.RadialChart>
    <h3>{ title }</h3>
    <p>Meta: { goal }</p>
    <p>Produção: { production }</p>
    <p>N/C: { nc }</p>
  </Styled.Container>
);

export default ProductionCell;
