import { ChangeEvent, useEffect, useState } from 'react';
import GoBackButton from "../../../components/buttons/GoBack";
import AccentButton from "../../../components/buttons/AccentButton";
import * as Styled from "./styled";
import { Row } from '../../../components/Row/styled';
import CreateCell from '../../../components/forms/createCell';
import StyleCellsView from '../../../components/buttons/StyleView';
import AddStation from '../../../components/buttons/AddStation';
import FieldStation from '../../../components/FieldStation';
import Summary from '../../../components/Summary';
import { useNavigate } from 'react-router-dom';


import img1 from './imgs/Rectangle 2428.png';
import img2 from './imgs/Rectangle 2429.png';
import img3 from './imgs/Rectangle 2420.png';
import ProductionCellDto from '../../../dto/production-cell.dto';
import WorkstationDto from '../../../dto/workstation.dto';
import CellsService from '../../../services/cells.service';
import { parseError } from '../../../config/error-handler';
import EditCellDto from '../../../dto/edit-cell.dto';
import EditWorkstationDto from '../../../dto/edit-workstation.dto';
import { toast } from 'react-toastify';

  interface LocationState {
    name: string;
    producao: string;
    meta: string;
    workstations: WorkstationDto[];

    cellData : ProductionCellDto
  }

  const EditCellsPage = () => {
    const cellService = new CellsService()

    const [cellData, setCellData] = useState<ProductionCellDto>({
      id: 0,
      title: "", 
      productionType: "",
      goal: 0, 
      production: 0, 
      nc: 0, 
      workedHours: 0, 
      notWorkedHours: "",
      workstations: [], 
    })

    //Id da celula
    const [id, setId] = useState<number>(0)

    //Producao da celula
    const [producao, setProducao] = useState("");

    //Meta da celula
    const [meta, setMeta] = useState("");

    //Layout da celula
    const [layout, setLayout] = useState<number>(1);
    
    //Workstations da celula
    const [workstations, setWorkstations] = useState<EditWorkstationDto[]>([])

    const [finalIndex, setFinalIndex] = useState<number>()

    const [workstationsExclude, setWorkstationsExclude] = useState<EditWorkstationDto[]>([])
    
    const [stationCount, setStationCount] = useState<number>(workstations.length);
    
    const [selectedStationIndex, setSelectedStationIndex] = useState<number | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
      const editCellData = sessionStorage.getItem('editCellData');
      if (editCellData) {
        const {id ,producao, workstations, cellData } = JSON.parse(editCellData);
        setId(id);
        setProducao(producao); 
        setCellData(cellData);

        workstations.map((ws : WorkstationDto) => {
          const editWs : EditWorkstationDto = {
            id: ws.id,
            goal: ws.goal,
            isFinal: ws.isFinal,
            orderOnCell: ws.orderOnCell
          }
          setWorkstations((state) => [...state, editWs])
        })

        setStationCount(workstations.length)
        sessionStorage.removeItem('editCellData');
      }
    }, []);

    const handleStationSelection = (index: number) => {
      setSelectedStationIndex(index === selectedStationIndex ? null : index);
    };

    //-------------------------------------------
    //  Adiciona workstation
    //-------------------------------------------

const incrementStationCount = () => {
      if (!producao) {
        toast.error("Por favor, preencha o campo de produção antes de adicionar uma estação.");
        return;
      }

    setStationCount(count => count + 1);

    const lastWs = workstations.length > 0 ? workstations[workstations.length - 1] : null;
    const newStation = {
      id: null,
      goal: 1,
      orderOnCell: lastWs ? lastWs.orderOnCell+1 : 1,
      isFinal: false
    };
    
    setWorkstations([...workstations, newStation])

    };

    //------------------------------------
    //  Célula changes
    //------------------------------------

    const handleProductionChange = (value: string) => {
      if (!/\d/.test(value)) {
        setProducao(value.toUpperCase());
      }
    };

    const handleImageClick = (image: number) => {
      setLayout(image);
    };

    const handleFinalWorkstation = (event : ChangeEvent<HTMLSelectElement>) => {
      const updatedWorkstations = workstations
      const index = parseInt(event.target.value)

      if(!finalIndex){
        const finalIndex : number = workstations.findIndex((ws) => ws.isFinal === true)
        updatedWorkstations[finalIndex].isFinal = false
        updatedWorkstations[index].isFinal = true
        setWorkstations(updatedWorkstations)
        setFinalIndex(index)
        return
      }
      updatedWorkstations[finalIndex].isFinal = false
      updatedWorkstations[index].isFinal = true
      setWorkstations(updatedWorkstations)
      setFinalIndex(index)
    };

    //--------------------------------
    //  Deleta cell
    //--------------------------------

    const handleDelete = (index : number) => {
      if (selectedStationIndex !== null) {
        setSelectedStationIndex(null); 
      }
    
      workstationsExclude.push(workstations[index])

      setWorkstations(workstations.filter((_,i) => i  !== index));

      setStationCount(count => count - 1);
    };

    //--------------------------------
    //  Salva célula
    //--------------------------------

    const saveCell = () => {
      workstations.map((ws, key) => {
        workstations[key] = {
          id: ws.id,
          goal: ws.goal,
          orderOnCell: ws.orderOnCell,
          isFinal: ws.isFinal
        }
      })
        
      const cell : EditCellDto = {
          name: producao,
          layout: layout,
          workstationsSave: workstations,
          workstationsExclude: workstationsExclude
        };
      
        cellService
          .editCell(id ,cell)
          .then(() => navigate(-1))
          .catch(parseError);
    }

    return (
      <>
        <GoBackButton
          text={"Editar Célula " + id}
          accentButton={
            <AccentButton
              onClick={saveCell}
              title="Salvar"
              style={{ width: '140px' }}
            />
          }
        />
        <Row>
          <Styled.LeftColumn>
            <Styled.Card>
              <Row>
                <CreateCell labelText="PRODUÇÃO" onChange={handleProductionChange} value={producao} />
                <Styled.SelectField>
                  <Styled.SelectLabel>EMPACOTAMENTO</Styled.SelectLabel>
                    <Styled.SelectProp 
                      onChange={(event) => handleFinalWorkstation(event)} 
                      name="FinalWorkstation" 
                      id="FinalWorkstation">
                      <option value="" hidden>Selecione um posto</option>
                      {workstations.map((ws : EditWorkstationDto, key) => {
                        return (<option key={key} value={key}>{producao + (key + 1)}</option>)
                      })}

                    </Styled.SelectProp>
                </Styled.SelectField>
              </Row>
              <Styled.H1>LAYOUT DA CÉLULA</Styled.H1>
              <Row>
                <StyleCellsView
                  labelText="Opção 1"
                  image={img1}
                  onClick={() => handleImageClick(1)}
                  isSelected={layout === 1}
                />
                <StyleCellsView
                  labelText="Opção 2"
                  image={img2}
                  onClick={() => handleImageClick(2)}
                  isSelected={layout === 2}
                />
                <StyleCellsView
                  labelText="Opção 3"
                  image={img3}
                  onClick={() => handleImageClick(3)}
                  isSelected={layout === 3}
                />
              </Row>
            </Styled.Card>
            <Styled.FullCard>
              <Summary
                selectedStationIndex={selectedStationIndex}
                meta={selectedStationIndex !== null ? cellData.workstations[selectedStationIndex]?.goal.toString() : cellData.goal.toString()}
                productionTotal={selectedStationIndex !== null ? cellData.workstations[selectedStationIndex]?.unitsProduced.toString() : cellData.production.toString()}
                productionNC={selectedStationIndex !== null ? cellData.workstations[selectedStationIndex]?.unitsProducedNc.toString() : cellData.nc.toString()}
                workedHours={selectedStationIndex !== null ? cellData.workstations[selectedStationIndex]?.workedHours.toString() : cellData.workedHours.toString()}
                unavailability={selectedStationIndex !== null ? cellData.workstations[selectedStationIndex]?.notWorkedHours : cellData.notWorkedHours.toString()}
              />
            </Styled.FullCard>
          </Styled.LeftColumn>
          <Styled.RightColumn >
            <Styled.Card >
              <Row style={{ justifyContent: 'space-between' }}>
                <Styled.H1>POSTO DE TRABALHO</Styled.H1>
                <AddStation onClick={incrementStationCount} isProductionFilled={!!producao} />
              </Row>
              {workstations.map((ws, index) => (
                <FieldStation
                  key={index}
                  isFinal={ws.isFinal}
                  stationIndex={index + 1}
                  isSelected={selectedStationIndex === index}
                  onSelect={() => handleStationSelection(index)}
                  stationName={`${index + 1}`}
                  productionText={producao}
                  status={true}
                  handleDelete={() => handleDelete(index)}
                  stopReason={""}
                />
              ))}
            </Styled.Card>
          </Styled.RightColumn>
        </Row>
      </>
    );
  };

  export default EditCellsPage;

