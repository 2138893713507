import styled from 'styled-components';
import TextField from "@mui/material/TextField";

export const Container = styled.div`
  background-color: #fff;
  width: 550px;
  padding: 0px 10px 0px 10px;
`;

export const InputField = styled.div`
  margin: 20px 0;
  
`;

export const Label = styled.div`
  font-family: var(--Jost-font);
  font-size: 17px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px; /* Adicionando margem inferior para espaçamento */
`;

export const Input = styled(TextField)`
  width: 100%;
`;
