import { toast } from "react-toastify";
import axios from "axios";
import Service from "./service";
import ProductionCellDto from "../dto/production-cell.dto";
import ProductionEmployeeDto from "../dto/production-employee-data.dto";
import PackagingDto from "../dto/packaging.dto";
import OptionDto from "../dto/option.dto";
import ProductionDto from "../dto/production.dto";

class DashboardService extends Service {
  
  constructor() {
    super();
  }

  async getProductions(cellId : number): Promise<ProductionCellDto> {
    try {
      const response = await this.remoteRequest.get<ProductionCellDto>(`${process.env.REACT_APP_BASE_URL}cell/all/${cellId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter produções:', error);
      toast.error('Erro ao obter produções');
      throw error;
    }
  }

  async getUsersByCell(cellId: number): Promise<ProductionEmployeeDto[]> {
    try {
      const response = await this.remoteRequest.get<ProductionEmployeeDto[]>(`${process.env.REACT_APP_BASE_URL}user/cell/${cellId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter produções por funcionário:', error);
      toast.error('Erro ao obter produções por funcionário');
      throw error;
    }
  }  

  async getPackaging(cellId: number): Promise<PackagingDto> {
    try {
      const [packagingResponse, productionResponse] = await Promise.all([
        this.remoteRequest.get<PackagingDto>(`${process.env.REACT_APP_BASE_URL}cell/packaging/${cellId}`),
        this.remoteRequest.get<ProductionCellDto>(`${process.env.REACT_APP_BASE_URL}cell/${cellId}`)
      ]);
  
      const packagingData = packagingResponse.data;
      const productionData = productionResponse.data;
  
      // Check if production data and workstations are defined
      if (productionData && productionData.workstations) {
        // Calculate total worked hours
        let totalWorkedHours = 0;
        productionData.workstations.forEach(workstation => {
          if (workstation && workstation.workSessions) {
            workstation.workSessions.forEach(session => {
              // Check if session and startTime are defined
              if (session && session.startTime) {
                // Calculate worked hours based on startTime and endTime
                if (session.endTime) {
                  const start = new Date(session.startTime);
                  const end = new Date(session.endTime);
                  const milliseconds = end.getTime() - start.getTime();
                  const hours = milliseconds / (1000 * 60 * 60);
                  totalWorkedHours += hours;
                }
              }
            });
          }
        });
  
        // Add total worked hours to packaging data
        packagingData.worked = totalWorkedHours;
      } else {
        console.error('Dados de produção inválidos:', productionData);
      }
  
      return packagingData;
    } catch (error) {
      console.error('Erro ao obter embalagem:', error);
      toast.error('Erro ao obter embalagem');
      throw error;
    }
  }
  

  async getFilterOptions(): Promise<OptionDto[]> {
    try {
      const response = await this.remoteRequest.get<OptionDto[]>(`${process.env.REACT_APP_BASE_URL}view/optionsCell`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter opções de filtro:', error);
      toast.error('Erro ao obter opções de filtro');
      throw error;
    }
  }

  async getViewOptions(): Promise<OptionDto[]> {
    try {
      const response = await this.remoteRequest.get<OptionDto[]>(`${process.env.REACT_APP_BASE_URL}view/optionsLabel`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter opções de visualização:', error);
      toast.error('Erro ao obter opções de visualização');
      throw error;
    }
  }

  async getAllCells(cellId : number): Promise<ProductionCellDto> {
    try {
      const response = await this.remoteRequest.get<ProductionCellDto>(`${process.env.REACT_APP_BASE_URL}cell/all/${cellId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter todas as células:', error);
      toast.error('Erro ao obter todas as células');
      throw error;
    }
  }

  async getTvData(idCell : number, startTime : Date) : Promise<ProductionDto[]>{
    try{
      const response = await axios.get<ProductionDto[]>(`${process.env.REACT_APP_BASE_URL}cell/tvData/${idCell}/${startTime}`)
      return response.data;
    } catch (error) {
      console.error('Erro ao obter dados da TV:', error);
      toast.error('Erro ao obter dados da TV');
      throw error;
    }
  }
}

export default DashboardService;

