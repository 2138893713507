import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import Header from "../../components/template/Header";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AccentButton from "../../components/buttons/AccentButton";
import Cell from "../../components/Cell";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate, useLocation } from 'react-router-dom';
import ProductionCellDto from "../../dto/production-cell.dto";
import { RowDasCelulas } from "../../components/Navbar/styled";
import CellsService from "../../services/cells.service";
import { parseError } from "../../config/error-handler";

const CellsPage = () => {
  const cellService = new CellsService()
  
  const [cellData, setCellData] = useState<ProductionCellDto[]>([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    cellService
      .getAllProductions()
      .then(setCellData)
      .catch(parseError)

}, []);

  const addNewCell = () => {
    navigate('/cells/new');
  }

  const handleDelete = (id: number, index : number) => {
    
    const updatedCellsData = cellData.filter((Cell : ProductionCellDto, i) => i !== index);
  
    cellService
      .deleteCell(id)
      .then(() => {
        window.location.reload()
      })
      .catch(parseError);
  };
  
  return (
    <>
      <Header title='Células de produção'>
        <AccentButton
          onClick={addNewCell}
          title="Nova Célula"
          style={{ width: '170px' }}
        >
          <AddCircleOutlineIcon />
        </AccentButton>
      </Header>

      <Styled.CellsContainer>
        <RowDasCelulas>
          {cellData.map((cell, index) => (
            <Cell key={index} cellData={cell} onDelete={(id) => handleDelete(id, index)} />
          ))}
          </RowDasCelulas>
      </Styled.CellsContainer>

    </>
  );
};

export default CellsPage;
