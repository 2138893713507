import React, { useState } from "react";
import * as Styled from './styles';
import CheckBox from "../../../../../components/CheckBox"; 
import CellsService from "../../../../../services/cells.service";
import EditSvg from "../../../../../components/svgs/edit";
import DeleteSvg from "../../../../../components/svgs/delete";
import PopUp from "../PopUp";
import ProductionCellDto from "../../../../../dto/production-cell.dto";
import WorkstationDto from "../../../../../dto/workstation.dto";
import EditWorkstationDto from "../../../../../dto/edit-workstation.dto";
import { parseError } from "../../../../../config/error-handler";
import { toast } from "react-toastify";

const cellService = new CellsService();

interface LineCheckProps {
  cellList: ProductionCellDto;
  onClick: (id: WorkstationDto) => void;
  onDelete: (ws:WorkstationDto) => void;
  selectedCells: number[];
}

const LineCheck = ({ cellList, onClick, onDelete,selectedCells }: LineCheckProps) => {
  console.log(cellList.workstations)

  const [wsToEdit, setWsToEdit] = useState<WorkstationDto | null>({
    id: 0,
    goal: 0,
    orderOnCell: 0,
    unitsProduced: 0,
    unitsProducedNc: 0,
    workedHours: 0,
    notWorkedHours: "",
    isFinal: false,
    workSessions: []
  })

  const [showPopUp, setShowPopUp] = useState(false)

  function handleEditWs(ws : WorkstationDto){
    setWsToEdit(ws)
    setShowPopUp(true)  
  }  

  function handlePopUpSave(newGoal : number){
    if(wsToEdit == null){
      return alert("erro")
    }

    if(newGoal <= 0){
      toast.error("A meta precisa ser um número maior que zero!!")

      return
    } 
    wsToEdit.goal = newGoal
    const editWorstation : EditWorkstationDto = {
      orderOnCell: wsToEdit.orderOnCell,
      id: wsToEdit.id,
      goal: newGoal,
      isFinal: wsToEdit.isFinal
    }

    cellService
      .editWorkstation(wsToEdit.id, editWorstation)
      .then()
      .catch()

    setShowPopUp(false)
    setWsToEdit(null)
  }

  function handlePopUpCancel(){
    setShowPopUp(false)
  }

  return (
    <>
    {showPopUp && wsToEdit && (
        <PopUp
          goal={wsToEdit.goal}
          onSave={handlePopUpSave}
          onCancel={handlePopUpCancel}
        />
    )}
    <Styled.Content>
      <thead>
        <Styled.Header>
          <Styled.TextHeader> </Styled.TextHeader>
          <Styled.TextHeader>POSTO</Styled.TextHeader>
          <Styled.TextHeader>ORDEM</Styled.TextHeader>
          <Styled.TextHeader>PRODUÇÃO</Styled.TextHeader>
          <Styled.TextHeader>META</Styled.TextHeader>
          <Styled.TextHeader>FINAL</Styled.TextHeader>
          <Styled.TextHeaderIcons> </Styled.TextHeaderIcons>
        </Styled.Header>
      </thead>

      <tbody>
      { cellList.workstations?.map((ws) => (
          <Styled.ContainerLine key={ws.id}>
            <Styled.Item onClick={() => onClick(ws)}>
              <CheckBox isChecked={selectedCells.includes(ws.id)} />
            </Styled.Item>
            <Styled.Item>{cellList.title}</Styled.Item>
            <Styled.Item>{ws.orderOnCell}</Styled.Item>
            <Styled.Item>{cellList.productionType}</Styled.Item>
            <Styled.Item>{ws.goal}</Styled.Item>
            <Styled.Item>{ws.isFinal ? "✅" : ""}</Styled.Item>
        
            <Styled.ItemIcons>
              <Styled.ContentButton>
                <Styled.ButtonEdit onClick={() => handleEditWs(ws)}>
                  <EditSvg height={18} width={18} /> <span>Editar</span>
                </Styled.ButtonEdit> 

                <Styled.ButtonDelete onClick={(e) => { e.stopPropagation(); onDelete(ws); }}>
                  <DeleteSvg /> <span>Excluir</span>
                </Styled.ButtonDelete>
              </Styled.ContentButton>
            </Styled.ItemIcons>
          </Styled.ContainerLine>
        ))
      }
      </tbody>
    </Styled.Content>
    </>
  );
}

export default LineCheck;
