import RoutePath from "../models/route-path.model";
import CellsPage from "../pages/cells";
import EditCellsPage from "../pages/cells/edit";
import NewCellsPage from "../pages/cells/new";
import DashboardPage from "../pages/dashboard";
import UsersPage from "../pages/users";
import UsersPageEdit from "../pages/users/edit";
import UsersPageNew from "../pages/users/new";
import ViewGoals from "../pages/cells/viewGoals";
import RegisterGoals from "../pages/cells/registerGoals";


// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const protectedRoutes: RoutePath[] = [
  { path: '/', element: DashboardPage },
  { path: '/dashboard', element: DashboardPage },
  { path: '/cells', element: CellsPage },
  { path: '/cells/new', element: NewCellsPage},
  { path: '/cells/edit', element: EditCellsPage},
  { path: '/users', element: UsersPage},
  { path: '/users/new', element: UsersPageNew},
  { path: '/users/edit', element: UsersPageEdit},
  { path: '/cells/view-goals', element: ViewGoals},
  { path: '/cells/register-goals', element: RegisterGoals},
];

export default protectedRoutes;
