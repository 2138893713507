import * as Styled from "./styled";
import ProductionCellDto from "../../dto/production-cell.dto";
import ProductionCell from "../ProductionCell";

const ProductionCells = ({ data }: { data: ProductionCellDto }) => (
  <Styled.Container>
      <ProductionCell 
        key={data.id} // Usando o id da sessão como chave única
        title={data.title} 
        goal={data.goal} 
        productionType={data.productionType}
        production={data.production} 
        nc={data.nc} 
        id={data.id}
        workstations={data.workstations ?? []} 
        workedHours={data.workedHours}
        notWorkedHours={data.notWorkedHours}
      />
  </Styled.Container>
);

export default ProductionCells;
