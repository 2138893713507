import { 
  Routes, 
  Route,
  BrowserRouter,  
  Navigate
} from "react-router-dom";
import protectedRoutes from "./protected";
import publicRoutes from "./public";
import AuthenticatedLayout from "../pages/_layouts/Authenticated";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------

const Rotas = () => (
  <BrowserRouter>
    <Routes>
      { buildPublicRoutes() }
      <Route element={<AuthenticatedLayout />}>
        { buildProtectedRoutes() }
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Rotas;

interface RouteProps {
  element: JSX.Element;
  [key: string]: any;
}

const PublicRoute = ({ element }: RouteProps) => (
  !isAuthenticated() 
    ? element
    : <Navigate to="/" />
);

const ProtectedRoute = ({ element }: RouteProps) => (
  hasAuthenticationToken() 
    ? element 
    : <Navigate to="/login" />
);


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function buildPublicRoutes() {
  const routeSwitch: JSX.Element[] = [];

  publicRoutes.forEach((route, index) => {
    routeSwitch.push(<Route key={index} path={route.path} element={ <PublicRoute element={<route.element />} /> } />);
  })

  return routeSwitch;
}

function buildProtectedRoutes() {
  const routeSwitch: JSX.Element[] = [];

  protectedRoutes.forEach((route, index) => {
    routeSwitch.push(<Route key={index} path={route.path} element={ <ProtectedRoute element={<route.element />} /> } />);
  })

  return routeSwitch;
}

function isAuthenticated() {
  return localStorage.getItem("isAuthenticated");
}

function hasAuthenticationToken() {
  return localStorage.getItem("x_auth_token");
}