import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Rotas from "./routes";

// --------------------------------------------------------------------------
//         Components
// --------------------------------------------------------------------------
const App = () => (
  <>
    <ToastContainer position="top-right" />
    <Rotas />
  </>
);

export default App;
