import React, { ChangeEvent, useEffect, useState } from "react";
import * as Styled from "./styled";
import CellsService from "../../../services/cells.service";
import Header from "../../../components/template/Header";
import LineCheck from "./components/LineCheck";
import DeleteSvg from "../../../components/svgs/delete";
import ProductionCellDto from "../../../dto/production-cell.dto";
import { parseError } from "../../../config/error-handler";
import DashboardService from "../../../services/dashboard.service";
import OptionDto from "../../../dto/option.dto";
import WorkstationDto from "../../../dto/workstation.dto";
import { toast } from "react-toastify";

const cellsService = new CellsService();
const dashboardService = new DashboardService();

const RegisterGoals = () => {
  //Células 
  const [responseCell, setResponseCell] = useState<ProductionCellDto>({
    id: 0,
    title: "", 
    productionType: "",
    goal: 0, 
    production: 0, 
    nc: 0, 
    workedHours: 0, 
    notWorkedHours: "",
    workstations: []
  });
  const [selectedCell, setSelectedCell] = useState<number[]>([]); 
  const [filterOpt, setFilterOpt] = useState("");
  const [filter, setFilter] = useState<OptionDto[]>();

  useEffect(() => {
    dashboardService
      .getFilterOptions()
      .then(filters => {
        cellsService
          .getProductions(filters[0].id)
          .then(state => setResponseCell(state))
          .catch(parseError)

        setFilter(filters)
        })
      .catch(parseError)
  }, []);

function handleDelete(ws: WorkstationDto){
    if(ws.isFinal){
      toast.error("Você não pode deletar um Posto Final !!")
      return
    }

    let updatedWs = responseCell.workstations
    updatedWs = updatedWs.filter(workstation => ws.id !== workstation.id)
    setResponseCell({...responseCell, workstations: updatedWs })
    cellsService
      .deleteWorkstation(ws.id)
      .then()
      .catch(parseError)
  };

  const handleDeleteAll = () => {
    let updatedworkstations = responseCell.workstations
    selectedCell.map(id => {
      updatedworkstations = updatedworkstations.filter(worskstation => worskstation.id !== id)
      cellsService.deleteWorkstation(id).then()
    })
    setResponseCell({...responseCell, workstations:updatedworkstations })  
  }

  function handleFilterChange(e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
    const value = e.target.value

    cellsService
      .getProductions(parseInt(value))
      .then(setResponseCell)
      .catch(parseError)

    setFilterOpt(value)
  }
  
  const DropdDown = (): JSX.Element => (
    <Styled.Filter>
      <Styled.InputDefault
      size="small"
      select
      value={filterOpt}
      onChange={event => handleFilterChange(event)}
      SelectProps={{
        native: true,
      }}
      >
      {filter?.map((filtro, index) => (
        <option key={filtro.id} value={filtro.id}>
          {filtro.label}
        </option>
      ))}
    </Styled.InputDefault>
  </Styled.Filter>
  ); 

  const ChildrenHeader = (): JSX.Element => (
    <Styled.ContainerChildrenHeader>
      <Styled.ButtonDeleteAll onClick={() => handleDeleteAll()}>
        <DeleteSvg />
        <span>Excluir selecionados</span>
      </Styled.ButtonDeleteAll>
      <DropdDown />
    </Styled.ContainerChildrenHeader>
  );

  const handleSelectCell = (ws: WorkstationDto) => {
    if(ws.isFinal){
      toast.error("Você não pode excluir um posto final !!")
      return
    }

    setSelectedCell(prev => {
      const isAlreadySelected = prev.includes(ws.id);
      if (isAlreadySelected) {
        return prev.filter(id => id !== ws.id); 
      } else {
        return [...prev, ws.id]; 
      }
    });
  };

  return (
    <>
      <Header title={'Registro de Metas'} children={<ChildrenHeader />} />
      <Styled.ContentCellList>
        <LineCheck 
          cellList={responseCell} 
          onClick={handleSelectCell} 
          onDelete={handleDelete}
          selectedCells={selectedCell} 
        />
      </Styled.ContentCellList>
    </>
  );
}

export default RegisterGoals;
