import React, { useState } from 'react';
import * as Styled from "./styled";
import NewStation from '../NewStation';
import Popup from '../Popup';
import { toast } from "react-toastify";

interface FieldStationProps {
  stationIndex: number;
  isSelected: boolean;
  onSelect: () => void;
  productionText: string;
  isFinal : boolean;
  status: boolean;
  handleDelete: Function;
  stopReason: "" | "INTERVALO" | "AUSENTE";
  stationName?: string;
}

const FieldStation: React.FC<FieldStationProps> = ({ stationIndex, isSelected, onSelect, isFinal, productionText, handleDelete ,status, stopReason }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if(isFinal){
      toast.error("Você não pode deletar um posto final !!")
      return
    }

    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(stationIndex);
    closePopup();
  };

  const getStatus = () => {
    if (stopReason) {
      return stopReason === "INTERVALO" ? "Intervalo" : "Ausente";
    }
    return status ? "Ativado" : "Desativado";
  };

  return (
    <>
      {isVisible && (
        <Styled.Container key={stationIndex} isSelected={isSelected} isFinal={isFinal} onClick={onSelect}>
          <Styled.Content>
            <Styled.div>
              {isSelected ? (
                <Styled.CheckedIcon />
              ) : (
                <Styled.UncheckedIcon />
              )}
              <NewStation
                number={stationIndex}
                selected={isSelected}
                stopReason={stopReason}
                onClick={onSelect}
                fgColor={'#191D27'}
              />
              <Styled.StyledProductionText>{`${productionText}${stationIndex}`}</Styled.StyledProductionText>
            </Styled.div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Styled.StatusText>Status:</Styled.StatusText> {getStatus()}
            </div>           
            <div>
              <Styled.DeleteButton onClick={(event) => handleDeleteClick(event)}>
                <Styled.DeleteIcone />
                <span>Excluir</span>
              </Styled.DeleteButton>
            </div>
          </Styled.Content>
        </Styled.Container>
      )}
      {showPopup && (
        <Popup
          stationName={`${productionText}${stationIndex}`}
          onCancel={closePopup}
          onConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default FieldStation;
