import Styled from "styled-components";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

export const Wrapper = Styled.div`
  width: 100%;
`;

export const Table = Styled.table`
  width: 100%;
  border-collapse: collapse; 
`;

export const Header = Styled.tr` 
  //background-color: #454D5F;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  th:first-child { border-top-left-radius: 10px; }
  th:last-child { border-top-right-radius: 10px; }
`;

export const HeaderCell = Styled.th`
  padding: 10px;
  //border-radius: 10px;
  background: #454D5F;
`;

export const Body = Styled.tbody`
  background-color: white;
`;

export const Row = Styled.tr`
  border-bottom: 1px solid #DADADA;
`;

export const Cell = Styled.td`
  padding: 10px;
  border-bottom: 1px solid #DADADA;
`;

export const UserContainer = Styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = Styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding: 0px 10px 0px 0px;
`;

export const ActionButton = Styled.button`
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
`;

export const DeleteButton = Styled(ActionButton)`
  color: #B43131;
  gap: 5px;
`;

export const EditButton = Styled(ActionButton)`
  color: #554C25;
  gap: 5px;
`;

export const IconWrapper = Styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%; 
  background-color: #ccc;
  margin-right: 10px; 
`;

export const Icon = Styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%; 
`;

export const Name = Styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 10px;
`;