import React, { useEffect, useState } from "react";
import * as Styled from "./styled";

import CellsService from "../../../services/cells.service";
import { useNavigate } from "react-router-dom";

import Header from "../../../components/template/Header";
import ViewDate from "../../../components/viewDate";
import AccentButton from "../../../components/buttons/AccentButton";

const cellsService = new CellsService();

const ProductionCell = ({ cell }: { cell: any; }) => (
  <Styled.CellContent>
    <Styled.CellHeader>
      <Styled.CellTitle>Célula {cell.id}</Styled.CellTitle>
      <Styled.CellGoal>Meta: {cell.goal}</Styled.CellGoal>
    </Styled.CellHeader>
    <Styled.Cell>
      <Styled.PostList>
        {cell.workstations && cell.workstations.map((work: any) => (
          <Styled.Post key={work.id}>
            <Styled.PostLabel>Posto {work.orderOnCell}</Styled.PostLabel>
            <Styled.PostGoal>Meta: {work.goal}</Styled.PostGoal>
          </Styled.Post>
        ))}
      </Styled.PostList>
    </Styled.Cell>
  </Styled.CellContent>
);

const ChildrenHeader = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Styled.ContainerChildrenHeader>
      <ViewDate />
      <AccentButton onClick={() => navigate('/cells/register-goals')} style={{padding: 10, fontSize: 12}} title={'Registro de Metas'} />
    </Styled.ContainerChildrenHeader>
  );
};

const ViewGoals = () => {
  const [factoryGoal, setFactoryGoal] = useState<number>();
  const [responseCell, setResponseCell] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await cellsService.getAllProductions();
      setResponseCell(response);

      if (response.length > 0) {
        const totalGoal = response.reduce((acc: number, cell: any)  => acc + cell.goal, 0);
        setFactoryGoal(totalGoal);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header children={<ChildrenHeader />} title={'Visualização de metas'} />
      <Styled.ContentCellList>
        <Styled.FactoryGoalView>
          <Styled.TitleLabelGoal>
            Meta da Fábrica
          </Styled.TitleLabelGoal>
          <Styled.TitleGoal>
            {factoryGoal}
          </Styled.TitleGoal>
        </Styled.FactoryGoalView>
        <Styled.ViewList>
          <Styled.TitleLabelGoal>
            Células de Produção
          </Styled.TitleLabelGoal>
          <Styled.ViewRowList>
          {responseCell.length > 0 ? (
            responseCell.map((cell) => (
              <ProductionCell key={cell.id} cell={cell} />
            ))
          ) : (
            <p>Nenhuma célula encontrada.</p>
          )}
          </Styled.ViewRowList>
        </Styled.ViewList>
      </Styled.ContentCellList>
    </>
  );
}



export default ViewGoals;
