import * as Styled from "./styled";
import { useNavigate } from "react-router-dom";
import SignInForm from "../../components/forms/signIn";
import Logo from "../../components/Logo";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Login = () => {
    
  const navigate = useNavigate();

  return (
    <Styled.Container>
        <Styled.Columns>
          <Styled.LeftColumn>
          </Styled.LeftColumn>
          <Styled.RightColumn>
            <Styled.Header>
              <Logo />
              <Styled.Title>
                Olá! Seja bem-vindo
              </Styled.Title>
            </Styled.Header>
            <Styled.Form>
              <SignInForm onSuccess={() => handleSignIn(navigate)} />
            </Styled.Form>
          </Styled.RightColumn>
        </Styled.Columns>
    </Styled.Container>
  );
};

export default Login;


// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function handleSignIn(navigate: any) {
  setTimeout(() => {
    navigate("/");
    window.location.reload();
  }, 600);
}
