import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GoBackButton from "../../../components/buttons/GoBack";
import AccentButton from "../../../components/buttons/AccentButton";
import * as Styled from "./styled";

import UserService from "../../../services/user.service"
import { parseError } from "../../../config/error-handler";
import NewUserDto from "../../../dto/new-user.dto";
import { toast } from "react-toastify";

const UsersPageNew = () => {
  //Service
  const userService = new UserService()

  const navigate = useNavigate();
  const { state } = useLocation();
  const { pathname } = useLocation();

  const [user, setUser] = useState<NewUserDto>({
    name: "",
    email: "",
    phone: "",
    workstation: "",
    shift: "",
    password: "",
    picture: ""
  })

  const [displayImg, setDisplayImg] = useState("");

  const [formData, setFormData] = useState<FormData>(new FormData());

  // ------------------------------------------------------------------------
  //         Handlers
  // ------------------------------------------------------------------------

  const saveUser = () => {
    const validation = 
    user.name !== "" 
    && user.email !== "" 
    && user.phone !== ""  
    && user.workstation !== ""  
    && user.shift !== ""  
    && user.password !== "" ;

    if(!validation){
      toast.error("Preencha todos os dados !!")
    }else{
      if(formData == null){
        const imageFormData = new FormData();
        imageFormData.append('file', "");
  
        setFormData(imageFormData)
      }
  
      const blob = new Blob([JSON.stringify(user)], { 
        type: "application/json"
      })
  
      formData.append('user', blob)

      userService
        .createUser(formData)
        .then(() => navigate(-1))
        .catch(parseError)
    }
  }

  const handleEditPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result as string;
        setDisplayImg(imageUrl);
      };
      reader.readAsDataURL(file);

      const imageFormData = new FormData();
      imageFormData.append('file', file);

      setFormData(imageFormData)
    }else{

    }
  };
    
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({ ...prevUser, [name]: value }));
  };

  const handleShiftSelection = (e : React.ChangeEvent<HTMLSelectElement>) => {
    const shift = e.target.value

    setUser({...user, shift: shift  })  
  }

  return (
    <>
      <GoBackButton
        text="Adicionar novo usuário"
        accentButton={
          <AccentButton
            onClick={saveUser}
            title="Salvar"
            style={{ width: '140px' }}
          />
        }
      />

      <Styled.Card>
        <Styled.Column>
          <Styled.Button>
            <Styled.UserAvatar>
              {displayImg ? <img src={displayImg} alt="Avatar" /> : <AddCircleOutlineIcon />}
            </Styled.UserAvatar>
            <Styled.Subtitle>
              <label htmlFor="avatar-upload">Editar foto</label>
              <input
                id="avatar-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleEditPhoto}
              />
            </Styled.Subtitle>
          </Styled.Button>
        </Styled.Column>


        <Styled.Column>
          <Styled.InputContainer>
            <Styled.Label>Nome de usuário</Styled.Label>
            <Styled.Input width="427px" name="name" value={user.name} onChange={handleChange} />
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Styled.Label>Posto</Styled.Label>
            <Styled.Input width="427px" name="workstation" value={user.workstation} onChange={handleChange}/>
          </Styled.InputContainer>
          <Styled.Row>
            <Styled.InputContainer>
              <Styled.Label>Senha</Styled.Label>
              <Styled.Input type="password" width="194px" name="password" value={user.password} onChange={handleChange}/>
            </Styled.InputContainer>
          </Styled.Row>
        </Styled.Column>
        <Styled.Column>
          <Styled.InputContainer>
            <Styled.Label>E-mail</Styled.Label>
            <Styled.Input width="308px" name="email" value={user.email} onChange={handleChange}/>
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Styled.Label>Turno Atual</Styled.Label>
            <Styled.Select name="shiftSelection" onChange={handleShiftSelection}>
              <option value="None" selected hidden>Selecionar turno</option>
              <option value="Manha">Manhã</option>
              <option value="Tarde">Tarde</option>
              <option value="Noite">Noite</option>
            </Styled.Select>
            {/*<Styled.Input width="307.91px" name="shift" value={user.shift} onChange={handleChange}/>*/}
          </Styled.InputContainer>
        </Styled.Column>
        <Styled.Column>
          <Styled.InputContainer>
            <Styled.Label>Telefone</Styled.Label>
            <Styled.Input width="193px" name="phone" value={user.phone} onChange={handleChange}/>
          </Styled.InputContainer>
        </Styled.Column>
      </Styled.Card>
    </>
  );
}

export default UsersPageNew;
