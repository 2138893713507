import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const ProductionTable = styled.table`
  flex: 1;
  width: 100%;

  thead {
    tr {
      th {
        color: #454D5F;
        text-align: left;
        font-weight: 400;
        line-height: 24.5px;
        text-transform: uppercase;
        font-family: var(--Jost-font);
      }

      td:not(:last-child) {
        margin-right: 20px;
      }

      .name {
        width: 45%;
      }
    }
  }

  tbody {
    tr {
      td.chart {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -10px;
      }
    }
  }
`;
