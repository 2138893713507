import { useEffect, useState } from "react";
import CellsService from "../services/cells.service";
import ProductionCellDto from "../dto/production-cell.dto";
import { parseError } from "../config/error-handler";

interface ButtonStations {
  goal: string;
  totalProduction: string;
  production: string;
  workedHours: string;
  unavailability: string;
  performance: string;
  active: 'INTERVALO' | 'AUSENTE' | '';
}

const ButtonStations: ButtonStations[] = [
  {goal: '600', totalProduction: '2150', production: '12', workedHours: '1h45', unavailability: '1h10', performance: '10', active: ''},
  {goal: '400', totalProduction: '2250', production: '23', workedHours: '2h45', unavailability: '2h10', performance: '100', active: ''},
  {goal: '899', totalProduction: '2350', production: '24', workedHours: '3h45', unavailability: '3h10', performance: '200', active: 'AUSENTE'},
  {goal: '3400', totalProduction: '2450', production: '52', workedHours: '4h45', unavailability: '4h10', performance: '230', active: ''},
  {goal: '34400', totalProduction: '2550', production: '62', workedHours: '5h45', unavailability: '5h10', performance: '290', active: 'INTERVALO'},
  {goal: '5300', totalProduction: '2650', production: '26', workedHours: '6h45', unavailability: '6h10', performance: '300', active: ''},
  {goal: '5300', totalProduction: '2650', production: '26', workedHours: '6h45', unavailability: '6h10', performance: '300', active: ''},
  {goal: '5300', totalProduction: '2650', production: '26', workedHours: '6h45', unavailability: '6h10', performance: '300', active: ''},
  {goal: '5300', totalProduction: '2650', production: '26', workedHours: '6h45', unavailability: '6h10', performance: '300', active: ''},
  {goal: '5300', totalProduction: '2650', production: '26', workedHours: '6h45', unavailability: '6h10', performance: '300', active: ''},
];

export default ButtonStations;