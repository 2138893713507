import styled from "styled-components";

export const Content = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.tr`
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: #454D5F;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const TextHeader = styled.th`
  font-size: 14px;
  width: 10%;
`;

export const TextHeaderIcons = styled.th`
  font-size: 14px;
  width: 50%;
`;

export const ContainerLine = styled.tr`
  width: 100%;
  background-color: #fff;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Item = styled.td`
  text-align: center;
  width: 10%;
`;

export const ItemIcons = styled.td`
  width: 50%;
`;

export const ContentButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 20%;
`;

export const ButtonDelete = styled.div`
  color: #B43131;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ButtonEdit = styled.div`
  color: #554C25;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

