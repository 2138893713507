import styled, { css } from 'styled-components';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

interface ContainerProps {
  isFinal: boolean;
  isSelected: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 50px;
  margin: 0px 0px 0px 10px;
  margin-bottom: 10px;
  ${(props) => props.isFinal ? css`border: 2px solid black` : null }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: #80764b;
      border-width: 2px;
    `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
`;

export const UncheckedIcon = styled(RadioButtonUncheckedIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
`;

export const CheckedIcon = styled(RadioButtonCheckedIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  color: #554C25 ;
`;

export const StyledProductionText = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #191d27;
  cursor: default;
`;

export const div = styled.div`
  display: flex;
  align-items: center;
  `;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #B43131;
`;

export const DeleteIcone = styled(DeleteIcon)`
  margin-right: 5px;
  color: #B43131;
`;

export const StatusText = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  cursor: default;
`;

export const InputContainer = styled.div `
  height: 100%;
  display: flex;
  
`

export const Input = styled.input`
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  
  
`;